import { Controller } from 'stimulus';

function showInputErrorMsg(element, error_msg) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName != 'DIV') {
    element.classList.add('is-invalid');
    $(parentElement).append(
      `<div class="invalid-feedback">${error_msg}</div>`
    );
  }
}

function clearErrorMsg(element) {
  let parentElement = element.parentNode;
  if (parentElement.lastChild.nodeName == 'DIV') {
    element.classList.remove('is-invalid');
    parentElement.removeChild(element.parentNode.lastChild);
  }
}

function toggleReadMore(text_target, link_target) {
  $(text_target).slideToggle({
    duration: 400,
    complete: () => {
      $(text_target).toggleClass('hidden');

      if(text_target.classList.contains('hidden')) {
        link_target.innerText = link_target.dataset.readmore;
      } else {
        link_target.innerText = link_target.dataset.readless;
      }
    }
  });
}

function external_apply_without_showing_modal(button){
  if (button.dataset.external_apply_without_modal == 'true') {
    button.addEventListener('click', function() {
      if(button.classList.contains('external-apply')) {
        document.getElementById('external_apply_submit').click();
      }
      if(button.classList.contains('whatsapp-apply')) {
        document.getElementById('whatsapp_apply_submit').click();
      }
    });
  }
}

export default class extends Controller {
  static targets = [
    'jobApplyModal',
    'emailInput',
    'termsInput',
    'dataControlDescription',
    'showDataControlDescriptionBtn',
    'applyTopButton',
    'applyBottomButton',
    'applyWhatsappTopButton',
    'applyWhatsappBottomButton'
  ];

  connect() {
    if(this.hasApplyTopButtonTarget) {
      external_apply_without_showing_modal(this.applyTopButtonTarget);
      external_apply_without_showing_modal(this.applyBottomButtonTarget);
    }
    if(this.hasApplyWhatsappTopButtonTarget) {
      external_apply_without_showing_modal(this.applyWhatsappTopButtonTarget);
      external_apply_without_showing_modal(this.applyWhatsappBottomButtonTarget);
    }
  }

  submitCreateAlertForm(event) {
    let submitForm = true
    const  form = event.currentTarget.form;

    const emailInput = form.querySelector('[data-job-offers--external-apply-target="emailInput"]');
    const termsInput = form.querySelector('[data-job-offers--external-apply-target="termsInput"]');

    if (emailInput.value == null || emailInput.value == "" ) {
      showInputErrorMsg(emailInput, emailInput.dataset.error);
      submitForm = false
    }
    if (!termsInput.checked) {
      showInputErrorMsg(termsInput, termsInput.dataset.error);
      submitForm = false
    }

    if (submitForm == false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      window.open(event.currentTarget.dataset.url, '_blank').focus();
    }
  }

  toggleEmailInput(event) {
    event.preventDefault();
    if (event.currentTarget.value != null || event.currentTarget.value != "" ) {
      clearErrorMsg(event.currentTarget);
    }
  }

  toggleTermsAcceptance(event) {
    event.preventDefault();
    if (event.currentTarget.checked) {
      clearErrorMsg(event.currentTarget);
    }
  }

  showDataControlDescription(_event){
    toggleReadMore(this.dataControlDescriptionTarget, this.showDataControlDescriptionBtnTarget);
  }
}
